@import './reset.css';
@import './typography/index.css';

html,
body,
#root {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

html {
  box-sizing: border-box;
}
*,
*::before,
*::after {
  box-sizing: inherit;
}

#root {
  font-family: 'GothamRounded', sans-serif;
  overflow: hidden;
  background-color: #000;
  color: #fff;
  z-index: 0;
}

#player {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  display: none;
  align-items: center;
  background-color: #000;
  z-index: 1000;
}

#preloader {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  background-color: rgba(218, 62, 118, 1);
}

#preloader svg {
  width: 100%;
  height: auto;
}

.display {
  display: flex !important;
}

video {
  width: 100vw;
  position: absolute;
}

h1 {
  font-family: 'GothamRounded';
  font-size: 3em;
  font-weight: bold;
}

h2 {
  font-family: 'GothamRounded';
  font-size: 2.2vw;
}

h3 {
  font-family: 'GothamRounded';
  font-size: 2vw;
  line-height: 2.75vw;
}

h4 {
  font-family: 'GothamRounded';
  font-size: 1.5vw;
  line-height: 2.2vw;
}

p,
ul {
  font-family: 'GothamRounded';
  font-size: 1.2vw;
  line-height: 1.6vw;
}

::after {
  font-size: 1.2vw;
  line-height: 1.6vw;
}

a {
  text-decoration: none;
  color: #333;
}

a:hover {
  text-decoration: underline;
}

b {
  font-weight: bold;
}
